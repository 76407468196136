import { EXTERNAL_LINKS } from '@/config/external-links'
import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { trpc } from '@/modules/api/trpc/lib/browser'
import { useHammertimeRedirect } from '@/modules/utils/hooks/use-hammertime-redirect'

export function useFacebookLoginMutation(mutationOptions?: Parameters<typeof trpc.auth.facebookLogin.useMutation>[0]) {
  const { identifyUser, logEvent } = useAnalytics()
  const hammertimeRedirect = useHammertimeRedirect()

  return trpc.auth.facebookLogin.useMutation({
    ...mutationOptions,
    onSuccess: (...args) => {
      const data = args[0]
      const isUserCreated = data.userCreated ? 'yes' : 'no'

      logEvent('auth_success', { type: 'facebook', new_user: isUserCreated })

      identifyUser(data.sessionUser.id)
      hammertimeRedirect({ href: EXTERNAL_LINKS.dashboard })

      mutationOptions?.onSuccess?.(...args)
    },
  })
}
