import { atom } from 'jotai'
import { createIsolation } from 'jotai-scope'

const { Provider: TabsProvider, useAtom: useTabsAtom } = createIsolation()

export { TabsProvider, useTabsAtom }

export const currentTabAtom = atom(0)

export const idAtom = atom('')
