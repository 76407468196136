'use client'

import { Stack, TextField, Typography } from '@mui/material'
import { useTranslations } from 'next-intl'
import { useRef, type FormEvent } from 'react'

import { Button } from '@/modules/ui/components/button'

import { useDeleteAccountMutation } from '../../hooks/use-delete-account-mutation'

export function ResendDeleteAccountEmail() {
  const formRef = useRef<HTMLFormElement>(null)
  const t = useTranslations()
  const deleteAccountMutation = useDeleteAccountMutation({
    onSuccess: () => {
      formRef.current?.reset()
    },
  })

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const email = new FormData(event.currentTarget).get('email') as string

    deleteAccountMutation.mutate({
      email,
    })
  }

  return (
    <Stack gap={4} my={-4}>
      <Typography>{t('manageAccount.deactivateOrDelete.underDeletion')}</Typography>
      <Stack ref={formRef} component="form" onSubmit={handleSubmit} gap={4}>
        <TextField
          name="email"
          type="email"
          size="small"
          placeholder={t('manageAccount.deactivateOrDelete.emailPlaceholder')}
        />

        {deleteAccountMutation.error && <Typography>{deleteAccountMutation.error.message}</Typography>}

        <Stack direction="row" justifyContent="space-between">
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="error"
            disabled={deleteAccountMutation.isPending}
          >
            {t('common.delete')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
