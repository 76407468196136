'use client'

import { Error, Facebook } from '@mui/icons-material'
import { Alert, Checkbox, FormControlLabel, Link, TextField, Typography } from '@mui/material'
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'
import React, { useEffect } from 'react'

import { EXTERNAL_LINKS } from '@/config/external-links'
import { ROUTES } from '@/config/routes'
import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { HAMMERTIME_API_ERRORS, isHammerTimeError } from '@/modules/api/hammer-time/lib/errors'
import { Button } from '@/modules/ui/components/button'

import { useCredentialsLoginMutation } from '../../hooks/use-credential-login-mutation'
import { FacebookLoginButton } from '../facebook-login-button'
import classes from './login-form.module.scss'

type LoginFormProps = {
  readonly closeModal?: () => void
  readonly isReactivation?: boolean
}

export function LoginForm({ closeModal, isReactivation }: LoginFormProps) {
  const t = useTranslations()
  const router = useRouter()
  const { logEvent } = useAnalytics()

  const loginMutation = useCredentialsLoginMutation({
    onSuccess: () => {
      logEvent('auth_success', { new_user: 'no', type: 'email' })
      closeModal?.()
    },
    onError: (error) => {
      if (isHammerTimeError(error.shape?.data)) {
        if (error.shape.data.hammertimeError?.[0]?.code === HAMMERTIME_API_ERRORS.SESSION.USER_DEACTIVATED) {
          closeModal?.()
        }
      }
    },
  })

  function getErrorMessage() {
    const errorCode = isHammerTimeError(loginMutation.error?.shape?.data)
      ? loginMutation.error.shape.data.hammertimeError?.[0]?.code
      : null

    if (!errorCode) return t('error.unknown')

    return (
      {
        [HAMMERTIME_API_ERRORS.SESSION.ACCOUNT_LOCKED]: t('error.accountLocked'),
        [HAMMERTIME_API_ERRORS.SESSION.BAD_CREDENTIALS]: t('error.badCredentials'),
        [HAMMERTIME_API_ERRORS.SESSION.INVALID_ACTION_TYPE]: t('error.invalidActionType'),
        [HAMMERTIME_API_ERRORS.SESSION.USER_AGREEMENT_NEEDED]: t('error.TOSRequired'),
      }[errorCode] ?? t('error.unknown')
    )
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    logEvent('login_submit')
    const formData = new FormData(event.currentTarget)

    loginMutation.mutate({
      email: formData.get('email') as string,
      password: formData.get('password') as string,
      rememberMe: formData.get('rememberMe') === 'on',
      reactivateAccount: isReactivation,
    })
  }

  function redirectHome() {
    closeModal?.()
    router.push(ROUTES.HOME)
  }

  useEffect(() => {
    logEvent('login_page')
  }, [logEvent])

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        {loginMutation.isError && (
          <Alert severity="error" icon={<Error />}>
            {getErrorMessage()}
          </Alert>
        )}

        <TextField
          required
          name="email"
          type="text"
          label={t('auth.emailOrUsername')}
          placeholder={t('auth.emailOrUsername')}
          size="small"
          slotProps={{ input: { readOnly: loginMutation.isPending } }}
        />
        <TextField
          required
          name="password"
          type="password"
          label={t('auth.password')}
          placeholder={t('auth.password')}
          size="small"
          slotProps={{ input: { readOnly: loginMutation.isPending } }}
        />

        <div className={classes.rememberContainer}>
          <FormControlLabel name="rememberMe" label={t('auth.rememberMe')} control={<Checkbox defaultChecked />} />

          <Link href={EXTERNAL_LINKS.forgotPassword} underline="hover">
            {t('auth.forgotPassword')}
          </Link>
        </div>

        <Button
          analytics={{
            eventName: 'login_submit',
          }}
          isLoading={loginMutation.isPending || loginMutation.isSuccess}
          type="submit"
          variant="contained"
          size="large"
          disabled={loginMutation.isPending}
        >
          {t('auth.logIn')}
        </Button>
      </form>

      <span className={classes.divider}>{t('home.separator')}</span>

      <FacebookLoginButton
        analytics={{ eventName: 'facebook_auth_select' }}
        fullWidth
        variant="contained"
        size="large"
        startIcon={<Facebook />}
        className={classes.facebookButton}
        onError={redirectHome}
      >
        {t('auth.continueWithFacebook')}
      </FacebookLoginButton>

      <div className={classes.noAccount}>
        <Typography variant="body1" component="p" textAlign="center">
          {t('auth.noAccount')}
        </Typography>

        <Button variant="outlined" size="large" onClick={redirectHome}>
          {t('auth.join')}
        </Button>
      </div>
    </>
  )
}
