import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { trpc } from '@/modules/api/trpc/lib/browser'

export function useDeleteAccountMutation(mutationOptions?: Parameters<typeof trpc.users.delete.useMutation>[0]) {
  const { logEvent } = useAnalytics()

  return trpc.users.delete.useMutation({
    ...mutationOptions,
    onError: (...args) => {
      logEvent('delete_account_request_failed')
      mutationOptions?.onError?.(...args)
    },

    onSuccess: (...args) => {
      logEvent('delete_account_request_succeeded')

      mutationOptions?.onSuccess?.(...args)
    },
  })
}
