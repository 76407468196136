import { LoginDialog } from '@/modules/auth/components/login-dialog'
import { ManageAccountDialog } from '@/modules/manage-account/components/manage-account-dialog'
import { VerificationCheckoutDialog } from '@/modules/verification/components/verification-checkout-dialog'

// Make sure that the Components add to the ModalMap accepts open and onClose props
export const MODAL_MAP = {
  login: LoginDialog,
  dataExport: ManageAccountDialog,
  getVerified: VerificationCheckoutDialog,
} as const
