'use client'

import { useAtomValue } from 'jotai'

import { openModalAtom } from './atoms'
import { MODAL_MAP } from './consts'
import { type ModalProps } from './types'

export function ModalDispatchProvider() {
  const openedModal = useAtomValue(openModalAtom)

  if (!openedModal) return null

  const ModalComponent = MODAL_MAP[openedModal.key] as React.ComponentType<ModalProps[typeof openedModal.key]>

  return <ModalComponent {...openedModal.props} />
}
