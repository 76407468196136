import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { openModalAtom } from '../config/atoms'
import { type ModalKeys, type ModalProps } from '../config/types'

export function useModalDispatch() {
  const setOpenModal = useSetAtom(openModalAtom)

  return useCallback(
    <K extends ModalKeys>(key: K, props?: Omit<ModalProps[K], 'open' | 'onClose'>) => {
      const enhancedProps = {
        ...props,
        open: true,
        onClose: () => setOpenModal(null),
      }

      setOpenModal({ key, props: enhancedProps as ModalProps[K] })
    },
    [setOpenModal]
  )
}
