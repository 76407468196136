import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'

import { ROUTES } from '@/config/routes'
import { trpc } from '@/modules/api/trpc/lib/browser'

export function useDeactivateMutation(mutationOptions?: Parameters<typeof trpc.users.deactivate.useMutation>[0]) {
  const router = useRouter()
  const t = useTranslations('manageAccount.deactivateOrDelete')
  const logoutMutation = trpc.auth.logout.useMutation()

  const updateSignUpDataMutation = trpc.session.updateSignUpData.useMutation()

  return trpc.users.deactivate.useMutation({
    ...mutationOptions,
    onSuccess: async (...args) => {
      await logoutMutation.mutateAsync()
      await updateSignUpDataMutation.mutateAsync({
        flash_message: t('deactivateSuccess'),
      })

      router.push(ROUTES.HOME)

      mutationOptions?.onSuccess?.(...args)
    },
  })
}
