'use client'

import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import { useLocale } from 'next-intl'
import { useCallback } from 'react'

import('dayjs/locale/es')
import('dayjs/locale/fr')
import('dayjs/locale/pt')
import('dayjs/locale/it')
import('dayjs/locale/ru')
import('dayjs/locale/zh')
import('dayjs/locale/pl')
import('dayjs/locale/de')

dayjs.extend(utc)
dayjs.extend(localeData)

export function useLocaleDayjs() {
  const locale = useLocale()

  const getLocaleMonth = useCallback(
    (month: number) => {
      dayjs.locale(locale.split('-')[0])

      return dayjs()
        .localeData()
        .months()
        .map((month) => month.charAt(0).toUpperCase() + month.slice(1))[month]
    },
    [locale]
  )

  return {
    dayjs,
    getLocaleMonth,
  }
}
