'use client'

import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { useTranslations } from 'next-intl'

import { Tabs } from '@/modules/ui/components/tabs'
import { type DispatchedModalProps } from '@/modules/ui/utils/modal-dispatch'

import classes from './manage-account-dialog.module.scss'
import { DataExport } from '../data-export'
import { DeactivateOrDelete } from '../deactivate-or-delete'

export function ManageAccountDialog({ className, onClose, ...restProps }: DispatchedModalProps) {
  const t = useTranslations('manageAccount')

  const isMediumUp = useMediaQuery('(width >= 48em)')

  return (
    <Dialog fullScreen={!isMediumUp} className={clsx(classes.dialog, className)} onClose={onClose} {...restProps}>
      <DialogTitle variant="h3">{t('label')}</DialogTitle>

      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>

      <DialogContent>
        <Tabs.Root id="manage-account">
          <Tabs.List className={classes.tabList}>
            <Tabs.Button>{t('dataExport.label')}</Tabs.Button>
            <Tabs.Button>{t('deactivateOrDelete.label')}</Tabs.Button>
          </Tabs.List>

          <Tabs.Panel className={classes.tabPanel}>
            <DataExport />
          </Tabs.Panel>
          <Tabs.Panel className={classes.tabPanel}>
            <DeactivateOrDelete closeDialog={onClose} />
          </Tabs.Panel>
        </Tabs.Root>
      </DialogContent>
    </Dialog>
  )
}
