'use client'

import FacebookLogin, { type SuccessResponse } from '@greatsumini/react-facebook-login'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'

import { env } from '@/config/env'
import { ROUTES } from '@/config/routes'
import { HAMMERTIME_API_ERRORS, isHammerTimeError } from '@/modules/api/hammer-time/lib/errors'
import { Button } from '@/modules/ui/components/button'
import { type ButtonProps } from '@/modules/ui/components/button/button'
import { mainAlertAtom } from '@/modules/ui/components/main-alert/atoms'

import classes from './facebook-login-button.module.scss'
import { facebookErrorsAtom } from '../../atoms'
import { useFacebookLoginMutation } from '../../hooks/use-facebook-login-mutation'

type FacebookLoginButtonProps = Omit<ButtonProps, 'onClick'> & {
  readonly onSuccess?: () => void
  readonly onError?: () => void
  readonly isReactivation?: boolean
}

export function FacebookLoginButton({
  className,
  children = 'Facebook',
  onSuccess,
  onError,
  isReactivation,
  ...buttonProps
}: FacebookLoginButtonProps) {
  const t = useTranslations('auth')
  const setMainAlert = useSetAtom(mainAlertAtom)
  const setFacebookErrors = useSetAtom(facebookErrorsAtom)
  const router = useRouter()

  const { mutate, isPending, isSuccess } = useFacebookLoginMutation({
    onError: (error) => {
      if (isHammerTimeError(error.shape?.data)) {
        const hammertimeError = error.shape.data.hammertimeError

        if (hammertimeError?.some((error) => Object.values(HAMMERTIME_API_ERRORS.FACEBOOK).includes(error.code))) {
          setFacebookErrors(hammertimeError.map((error) => error.code))

          router.push(ROUTES.CREATE_ACCOUNT)

          return
        }
      }

      setMainAlert({
        severity: 'error',
        message: error.message,
      })

      onError?.()
    },
  })

  function handleSuccess(response: SuccessResponse) {
    mutate({
      authToken: response.accessToken,
      agreedWithTOSAndPP: true,
      reactivateAccount: isReactivation,
    })
    onSuccess?.()
  }

  function handleInsufficientAccessError() {
    setMainAlert({
      severity: 'warning',
      message: t('facebookError'),
    })

    onError?.()
  }

  return (
    <FacebookLogin
      appId={env('NEXT_PUBLIC_FACEBOOK_APP_ID')}
      onSuccess={handleSuccess}
      scope="public_profile,email,user_birthday,user_gender,user_location"
      onFail={handleInsufficientAccessError}
      render={({ onClick }) => (
        <Button
          className={clsx(classes.facebookButton, className)}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={onClick}
          isLoading={isPending || isSuccess}
          {...buttonProps}
        >
          {children}
        </Button>
      )}
    />
  )
}
