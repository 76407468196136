import { EXTERNAL_LINKS } from '@/config/external-links'
import { ROUTES } from '@/config/routes'
import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { trpc } from '@/modules/api/trpc/lib/browser'
import { useHammertimeRedirect } from '@/modules/utils/hooks/use-hammertime-redirect'

export function useCredentialsLoginMutation(
  mutationOptions?: Parameters<typeof trpc.auth.credentialsLogin.useMutation>[0]
) {
  const { identifyUser } = useAnalytics()
  const hammertimeRedirect = useHammertimeRedirect()

  return trpc.auth.credentialsLogin.useMutation({
    ...mutationOptions,
    onSuccess: (...args) => {
      const data = args[0]

      identifyUser(data.sessionUser.id)

      hammertimeRedirect({ href: EXTERNAL_LINKS.dashboard, devPath: ROUTES.PROTECTED.PLAYGROUND })

      mutationOptions?.onSuccess?.(...args)
    },
  })
}
