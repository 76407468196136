'use client'

import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import { useTranslations } from 'next-intl'

import { EXTERNAL_LINKS } from '@/config/external-links'
import { WEB_VIEW_PREFIX } from '@/config/routes'
import { type Plan } from '@/modules/checkout/atoms'
import { CardPayment } from '@/modules/checkout/components/card-payment'
import { PaypalButton } from '@/modules/checkout/components/paypal-button'
import { RecurlyAppProvider } from '@/modules/checkout/config/recurly'
import { usePriceFormatter } from '@/modules/i18n/hooks/use-price-formatter'
import { type DispatchedModalProps } from '@/modules/ui/utils/modal-dispatch'
import { useHammertimeRedirect } from '@/modules/utils/hooks/use-hammertime-redirect'

import classes from './verification-checkout-dialog.module.scss'

type VerificationCheckoutDialogProps = DispatchedModalProps & {
  readonly plan: Plan
}

export function VerificationCheckoutDialog({
  className,
  onClose,
  plan,
  ...restProps
}: VerificationCheckoutDialogProps) {
  const pathname = usePathname()
  const t = useTranslations()
  const formatPrice = usePriceFormatter({
    shouldHideCurrency: true,
  })
  const hammertimeRedirect = useHammertimeRedirect()

  const isSmall2Up = useMediaQuery('(width >= 30em)')

  function successRedirect() {
    hammertimeRedirect({
      href: pathname.startsWith(WEB_VIEW_PREFIX)
        ? EXTERNAL_LINKS.webView.verificationSucceeded(plan.amount, plan.currency)
        : EXTERNAL_LINKS.verificationSuccess,
    })
  }

  return (
    <Dialog fullScreen={!isSmall2Up} className={clsx(classes.dialog, className)} onClose={onClose} {...restProps}>
      <DialogTitle variant="h3">
        {t('verification.get_verified')}
        <Typography className={classes.priceCaption}>
          {t('verification.to_get_verified_sub_a')} {formatPrice(plan.amount, plan.currency)}{' '}
          {t('verification.to_get_verified_sub_latest_part1', { duration: t('verification.per_life') })}
        </Typography>
      </DialogTitle>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>

      <DialogContent dividers>
        <RecurlyAppProvider>
          <Stack gap={1}>
            <CardPayment plan={plan} successRedirect={successRedirect} />
            <span className={classes.divider}>{t('home.separator')}</span>
            <PaypalButton plan={plan} successRedirect={successRedirect} />
          </Stack>
        </RecurlyAppProvider>
      </DialogContent>
    </Dialog>
  )
}
