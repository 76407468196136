'use client'

import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { useTranslations } from 'next-intl'

import { type DispatchedModalProps } from '@/modules/ui/utils/modal-dispatch'

import classes from './login-dialog.module.scss'
import { LoginForm } from '../login-form'

type LoginDialogProps = DispatchedModalProps & {
  readonly isReactivation?: boolean
}

export function LoginDialog({ className, onClose, isReactivation, ...restProps }: LoginDialogProps) {
  const t = useTranslations('auth')

  const isMediumUp = useMediaQuery('(width >= 48em)')

  return (
    <Dialog fullScreen={!isMediumUp} className={clsx(classes.dialog, className)} onClose={onClose} {...restProps}>
      <DialogTitle variant="h3">{t(isReactivation ? 'reactivate.label' : 'logInToCouchsurfing')}</DialogTitle>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>

      <DialogContent dividers>
        <LoginForm closeModal={onClose} isReactivation={isReactivation} />
      </DialogContent>
    </Dialog>
  )
}
