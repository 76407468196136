import { type AlertProps } from '@mui/material'
import { atom } from 'jotai'
import { type ReactNode } from 'react'

type MainAlert = {
  severity: AlertProps['severity']
  message: ReactNode
  isAutoCloseDisabled?: boolean
  onClose?: () => void
}

export const mainAlertAtom = atom<MainAlert | null>(null)
