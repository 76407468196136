'use client'

import { Tab, Tabs as MuiTabs, type TabsProps, type TabProps } from '@mui/material'
import { Children, cloneElement, isValidElement, type PropsWithChildren, type SyntheticEvent } from 'react'

import { currentTabAtom, idAtom, TabsProvider, useTabsAtom } from './atoms'

type RootProps = {
  readonly initialTab?: number
  readonly id: string
  readonly children: Array<React.ReactElement<ListProps | PanelProps>>
}

function Root({ children, id, initialTab = 0 }: RootProps) {
  const tabListChild = (Children.toArray(children) as Array<React.ReactElement<ListProps | PanelProps>>).find(
    (child) => child.type === List
  )

  const tabPanelChildren = (Children.toArray(children) as Array<React.ReactElement<ListProps | PanelProps>>)
    .filter((child) => child.type === Panel)
    .map((child, index) => {
      if (isValidElement(child)) {
        return cloneElement(child, { value: index })
      }

      return child
    })

  return (
    <TabsProvider
      initialValues={
        [
          [currentTabAtom, initialTab],
          [idAtom, id],
        ] as const
      }
    >
      {tabListChild}
      {tabPanelChildren}
    </TabsProvider>
  )
}

// Could not use type union for some reason => using interface extend
interface ButtonProps extends Omit<TabProps, 'children' | 'value'> {
  readonly children: string
  readonly value?: number
}

function Button({ children, value, ...otherProps }: ButtonProps) {
  const [, setTab] = useTabsAtom(currentTabAtom)
  const [id] = useTabsAtom(idAtom)

  if (value === undefined) return null

  return (
    <Tab
      id={`${id}-tab-${value}`}
      aria-controls={`${id}-tab-${value}`}
      label={children}
      value={value}
      onClick={() => setTab(value)}
      {...otherProps}
    />
  )
}

type ListProps = Omit<TabsProps, 'value' | 'onChange' | 'children'> & {
  readonly value?: number
  readonly children: Array<React.ReactElement<ButtonProps>>
}

function List({ children, ...otherProps }: ListProps) {
  const [tab, setTab] = useTabsAtom(currentTabAtom)

  function handleTabChange(_event: SyntheticEvent, newTab: number) {
    setTab(newTab)
  }

  const tabButtonChildren = Children.map(children, (child, index) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (isValidElement(child)) {
      return cloneElement(child, { value: index })
    }

    return child
  })

  return (
    <MuiTabs value={tab} onChange={handleTabChange} {...otherProps}>
      {tabButtonChildren}
    </MuiTabs>
  )
}

type PanelProps = PropsWithChildren<{
  readonly value?: number
  readonly className?: string
}>

function Panel({ value, children, className }: PanelProps) {
  const [tab] = useTabsAtom(currentTabAtom)
  const [id] = useTabsAtom(idAtom)

  if (value !== tab) {
    return null
  }

  return (
    <div
      className={className}
      id={`${id}-tabpanel-${value}`}
      aria-labelledby={`${id}-${value}`}
      role="tabpanel"
      hidden={value !== tab}
    >
      {value === tab ? children : null}
    </div>
  )
}

export const Tabs = {
  Root,
  List,
  Panel,
  Button,
}
