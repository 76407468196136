'use client'

import { Alert, Skeleton, Stack, TextField, Typography } from '@mui/material'
import { useTranslations } from 'next-intl'
import { type FormEvent } from 'react'

import { trpc } from '@/modules/api/trpc/lib/browser'
import { Recaptcha } from '@/modules/auth/components/recaptcha'
import { Button } from '@/modules/ui/components/button'
import { type DispatchedModalProps } from '@/modules/ui/utils/modal-dispatch'

import classes from './delete-account.module.scss'
import { useDeleteAccountMutation } from '../../hooks/use-delete-account-mutation'
import { ResendDeleteAccountEmail } from '../resend-delete-account-email'

type DeleteProps = {
  readonly closeDialog: DispatchedModalProps['onClose']
}

export function DeleteAccount({ closeDialog }: DeleteProps) {
  const t = useTranslations()
  const utils = trpc.useUtils()
  const meQuery = trpc.users.me.useQuery()
  const deleteAccountMutation = useDeleteAccountMutation({
    onSuccess: async () => {
      await utils.users.me.invalidate()
    },
  })

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const email = new FormData(event.currentTarget).get('email') as string

    deleteAccountMutation.mutate({
      email,
    })
  }

  if (meQuery.isPending) {
    return (
      <Stack direction="column" gap={2}>
        <Skeleton variant="rectangular" width="100%" height={80} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Stack>
    )
  }

  if (deleteAccountMutation.isSuccess || meQuery.data?.isUnderDeletion) {
    return <ResendDeleteAccountEmail />
  }

  return (
    <Stack gap={4} my={-4}>
      <Typography fontWeight={700}>{t('manageAccount.deactivateOrDelete.deleteTitle')}</Typography>
      <Alert severity="error">
        <Typography fontWeight={700}>{t('manageAccount.deactivateOrDelete.deleteDisclaimer')}</Typography>
      </Alert>

      <Stack component="form" onSubmit={handleSubmit} gap={4}>
        <TextField
          name="email"
          type="email"
          size="small"
          placeholder={t('manageAccount.deactivateOrDelete.emailPlaceholder')}
        />
        <Recaptcha className={classes.recaptchaContainer}>
          {(hasPassed) => (
            <>
              {deleteAccountMutation.error && <Typography>{deleteAccountMutation.error.message}</Typography>}
              <Stack direction="row" justifyContent="space-between">
                <Button
                  analytics={{
                    eventName: 'delete_account_select',
                  }}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="error"
                  disabled={!hasPassed || deleteAccountMutation.isPending}
                >
                  {t('common.delete')}
                </Button>
                <Button size="large" variant="contained" color="primary" onClick={closeDialog}>
                  {t('common.cancel')}
                </Button>
              </Stack>
            </>
          )}
        </Recaptcha>
      </Stack>
    </Stack>
  )
}
