'use client'

import { Alert, Skeleton, Stack, Typography } from '@mui/material'
import { useTranslations } from 'next-intl'

import { EXTERNAL_LINKS } from '@/config/external-links'
import { trpc } from '@/modules/api/trpc/lib/browser'
import { Recaptcha } from '@/modules/auth/components/recaptcha'
import { Button } from '@/modules/ui/components/button'
import { useLocaleDayjs } from '@/modules/utils/hooks/use-locale-dayjs'

import classes from './data-export.module.scss'

const GDPR_VALIDITY_PERIOD = 30

export function DataExport() {
  const { dayjs, getLocaleMonth } = useLocaleDayjs()

  const t = useTranslations('manageAccount')
  const utils = trpc.useUtils()
  const exportMutation = trpc.users.exportData.useMutation({
    onSuccess: async () => {
      await utils.users.exportDataStatus.invalidate()
    },
  })

  const exportQuery = trpc.users.exportDataStatus.useQuery(undefined, {
    refetchOnMount: true,
  })

  const expiryDate = dayjs(exportQuery.data?.expiry)
  const createdAtDate = expiryDate.subtract(GDPR_VALIDITY_PERIOD, 'days')

  if (exportQuery.isPending) {
    return (
      <Stack direction="column" gap={2}>
        <Typography>{t('dataExport.expiryDisclaimer')}</Typography>
        <Typography>{t('dataExport.optimizationDisclaimer')}</Typography>
        <Skeleton variant="rectangular" width="100%" height={80} />
      </Stack>
    )
  }

  return (
    <Stack direction="column" gap={2}>
      <Typography>{t('dataExport.expiryDisclaimer')}</Typography>
      <Typography>{t('dataExport.optimizationDisclaimer')}</Typography>
      {!exportMutation.isSuccess && !exportQuery.data && (
        <Recaptcha className={classes.recaptchaContainer}>
          {(hasPassed) => (
            <Button
              size="large"
              disabled={!hasPassed || exportMutation.isPending || exportMutation.isSuccess}
              onClick={() => exportMutation.mutate()}
              variant="contained"
              className={classes.requestButton}
            >
              {t('dataExport.submitButton')}
            </Button>
          )}
        </Recaptcha>
      )}

      {(exportMutation.isSuccess || exportQuery.data?.status === 'pending') && (
        <>
          <Typography>{t('dataExport.requestReceived')}</Typography>
          <Typography>{t('dataExport.requestInstructions')}</Typography>
        </>
      )}

      {exportQuery.data?.status === 'ready' && (
        <Stack mt={8} gap={1} component="form" method="get" action={EXTERNAL_LINKS.downloadData}>
          <Button type="submit" variant="contained" className={classes.requestButton}>
            {t('dataExport.downloadData')}
          </Button>
          <Typography mt={1} variant="caption">
            {t('dataExport.fileCurrent', {
              year: createdAtDate.year(),
              month: getLocaleMonth(createdAtDate.month()),
              day: createdAtDate.date(),
            })}
          </Typography>
          <Typography variant="caption">
            {t('dataExport.fileExpires', {
              year: expiryDate.year(),
              month: getLocaleMonth(expiryDate.month()),
              day: expiryDate.date(),
            })}
          </Typography>
        </Stack>
      )}
      {exportMutation.error && <Alert severity="error">{exportMutation.error.message}</Alert>}
    </Stack>
  )
}
