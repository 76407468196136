import { useCallback } from 'react'

// Use this when you want to purge the next.js cache or when you want to redirect to hammertime
// Useful eg when navigating from public to protected routes and vice verse
// since this prevents the user from navigating back without running the auth middleware
export function useHardRedirect() {
  return useCallback((path: string) => {
    window.location.href = path
  }, [])
}
