'use client'

import { Alert, Stack, Typography } from '@mui/material'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import { Recaptcha } from '@/modules/auth/components/recaptcha'
import { htmlFormats } from '@/modules/i18n/lib/formats'
import { Button } from '@/modules/ui/components/button'
import { type DispatchedModalProps } from '@/modules/ui/utils/modal-dispatch'

import classes from './deactivate-or-delete.module.scss'
import { useDeactivateMutation } from '../../hooks/use-deactivate-mutation'
import { DeleteAccount } from '../delete-account'

type DeactivateOrDeleteProps = {
  readonly closeDialog: DispatchedModalProps['onClose']
}

export function DeactivateOrDelete({ closeDialog }: DeactivateOrDeleteProps) {
  const [shouldDisplayDelete, setShouldDisplayDelete] = useState(false)
  const t = useTranslations('manageAccount.deactivateOrDelete')
  const deactivateMutation = useDeactivateMutation({
    onSuccess: closeDialog,
  })

  if (shouldDisplayDelete) {
    return <DeleteAccount closeDialog={closeDialog} />
  }

  return (
    <Stack gap={4}>
      <Typography>{t.rich('deactivateDescription', htmlFormats)}</Typography>
      <Recaptcha className={classes.recaptchaContainer}>
        {(hasPassed) => (
          <Button
            onClick={() => deactivateMutation.mutate()}
            disabled={!hasPassed}
            size="large"
            variant="contained"
            className={classes.deactivateButton}
          >
            {t('deactivateButton')}
          </Button>
        )}
      </Recaptcha>

      {deactivateMutation.error && <Alert severity="error">{deactivateMutation.error.message}</Alert>}

      <Button className={classes.deleteLink} onClick={() => setShouldDisplayDelete(true)}>
        {t('deleteLink')}
      </Button>
    </Stack>
  )
}
