'use client'

import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA, { type ReCAPTCHAProps } from 'react-google-recaptcha'

import { IS_DEVELOPMENT } from '@/config'
import { env } from '@/config/env'
import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { type ANALYTICS_EVENT } from '@/modules/analytics/lib/const'

type RecaptchaProps = Omit<ReCAPTCHAProps, 'sitekey' | 'children'> & {
  readonly children?: (hasPassed: boolean) => React.ReactNode
  readonly analyticsPrefix?: string
}

export function Recaptcha({ className, children, onChange, analyticsPrefix, ...otherProps }: RecaptchaProps) {
  const [recaptchaToken, setRecaptchaToken] = useState<Nullable<string>>(null)
  const { logEvent } = useAnalytics()

  function handleChange(token: string | null) {
    if (analyticsPrefix) {
      logEvent(`${analyticsPrefix}_completed` as ANALYTICS_EVENT)
    }
    setRecaptchaToken(token)
    onChange?.(token)
  }

  function handleExpired() {
    if (analyticsPrefix) {
      logEvent(`${analyticsPrefix}_expired` as ANALYTICS_EVENT)
    }
  }

  function handleError() {
    if (analyticsPrefix) {
      logEvent(`${analyticsPrefix}_failed` as ANALYTICS_EVENT)
    }
  }

  useEffect(() => {
    if (analyticsPrefix) {
      logEvent(`${analyticsPrefix}_shown` as ANALYTICS_EVENT)
    }
  }, [analyticsPrefix, logEvent])

  return (
    <div className={className}>
      <ReCAPTCHA
        onErrored={handleError}
        onExpired={handleExpired}
        sitekey={env('NEXT_PUBLIC_RECAPTCHA_SITE_KEY')}
        onChange={handleChange}
        {...otherProps}
      />
      {children?.(IS_DEVELOPMENT || Boolean(recaptchaToken))}
    </div>
  )
}
