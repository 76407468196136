import { useRouter } from 'next/navigation'
import { useCallback } from 'react'

import { IS_DEVELOPMENT } from '@/config'

import { useHardRedirect } from './use-hard-redirect'

type HammertimeRedirectOptions = {
  href: string
  devPath?: string
}

export function useHammertimeRedirect() {
  const router = useRouter()
  const hardRedirect = useHardRedirect()

  return useCallback(
    ({ href, devPath }: HammertimeRedirectOptions) => {
      if (IS_DEVELOPMENT && devPath) {
        router.replace(devPath)
        router.refresh()
      } else {
        hardRedirect(href)
      }
    },
    [router, hardRedirect]
  )
}
