import { env } from './env'

export const EXTERNAL_LINKS = {
  // Environment-dependent Existing Couchsurfing Web
  forgotPassword: `${env('NEXT_PUBLIC_BASE_WEB_URL')}/users/password/new`,
  membershipPreference: `${env('NEXT_PUBLIC_BASE_WEB_URL')}/preferences/membership`,
  dashboard: `${env('NEXT_PUBLIC_BASE_WEB_URL')}/dashboard`,
  downloadData: `${env('NEXT_PUBLIC_BASE_WEB_URL')}/users/me/data`,
  verificationSuccess: `${env('NEXT_PUBLIC_BASE_WEB_URL')}/verification-success`,

  // web views
  webView: {
    verificationSucceeded: (amount?: number, currency?: string) =>
      `csapp:/verificationSucceeded?currency=${currency}&value=${amount}`,
  },

  // Hardcoded
  howItWorks: 'https://www.couchsurfing.com/about/how-it-works/',
  safety: 'https://www.couchsurfing.com/about/safety/',
  about: 'https://about.couchsurfing.com/about/about-us/',
  support: 'https://support.couchsurfing.com/',
  blog: 'https://blog.couchsurfing.com/',
  shop: 'https://store.couchsurfing.com/',

  // Social
  facebook: 'https://facebook.com/couchsurfing',
  instagram: 'https://instagram.com/couchsurfing',
  x: 'https://x.com/couchsurfing',
  tumblr: 'https://couchsurfing.tumblr.com/',

  // App Download
  iOSAppDownload: 'https://itunes.apple.com/us/app/couchsurfing/id525642917',
  androidAppDownload: 'https://play.google.com/store/apps/details?id=com.couchsurfing.mobile.android',

  // Legal
  terms: 'https://www.couchsurfing.com/about/terms-of-use',
  privacy: 'https://www.couchsurfing.com/about/privacy-policy',
  sitemap: 'https://www.couchsurfing.com/places',

  // Testimonials
  forbes: 'https://www.forbes.com/sites/alexandratalty/2014/04/08/couchsurfing-with-a-smile/#27aa06317b0e',
  time: 'https://content.time.com/time/travel/article/0,31542,2045092,00.html',
  nyt: 'https://frugaltraveler.blogs.nytimes.com/2009/04/08/3-cushions-a-million-guests',
  guardian:
    'https://www.theguardian.com/travel/2015/feb/10/why-id-rather-take-a-free-couchsurfer-than-make-money-from-airbnb',
}
